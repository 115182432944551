export const viewItem = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
};

export const sign_up = (email) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'sign_up',
    ecommerce: {
      value: email,

    },
  });
};


export const download_product = ({ item_id, item_name, user }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'download_product',
    ecommerce: {

      items: [
        {
          item_id,
          item_name,
          user: user,
        },
      ],
    },
  });

  // console.log(dataLayer);
};



export const PaidSubscription = ({ item_name },
) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'paid_subscription',
    ecommerce: {
      items: [
        {
          item_name
        },
      ],
    },
  });


};

export const addToCart = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
};

export const initiatePayment = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
};

export const purchaseEvent = (
  tx_id,
  price,
  { item_id, item_name, item_price },
) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: tx_id,
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });

  //console.log(dataLayer);
};

// export const checkoutCallback = (price, {item_id, item_name, item_price}, url, window) => {
//     const dataLayer = window.dataLayer;
//     if(!dataLayer) return;

//     dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//     dataLayer.push({
//         event: "begin_checkout",
//         eventCallback:  function(){
//             window.location.href = url;
//         },
//         ecommerce: {
//             value: price,
//             currency: "USD",
//             items: [
//             {
//                 item_id,
//                 item_name,
//                 price: item_price,
//             }]
//         }
//     });
// }
