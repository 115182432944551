import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { signIn } from 'next-auth/react';
import { toast } from 'react-toastify';
import User from 'services/UserService';
import { useSiteInfo } from '@/context/SiteInfoContext';
import LoginGoogle from './LoginGoogle';
import LoginShluchim from './LoginShluchim';
import { sign_up } from 'utils/gtag';
import { validateSignUpEmail } from 'utils/validationHelpers';
import CheckEmailModal from './CheckEmail';

const RegisterModal = ({ closeModal, show, openLoginModal, callback }) => {
  const [error, setError] = useState(false);
  const [verifyUser, setVerifyUser] = useState(false);
  const { siteInfo } = useSiteInfo();
  const [loading, setLoading] = useState(false);

  /**
   * Handles the submission of the registration form.
   *
   * @param {Event} e - The form submission event.
   * @returns {Promise<void>} - A promise that resolves when the registration process is complete.
   */
  const submitRegister = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(null);

      const userData = {
        first_name: e.target.firstname.value.trim(),
        last_name: e.target.lastname.value.trim(),
        email: e.target.email.value.trim().toLowerCase(),
        password: e.target.password.value,
        datecreated: new Date().toISOString(),
        site_id: { id: siteInfo.id },
        site_url: siteInfo.site_url,
        status: 'inactive',
      };

      if (userData.password.length < 8)
        return setError('Password should not be less than 8 characters');

      if (!validateSignUpEmail(userData.email)) {
        return setError(
          "Invalid email address. Please remove the '+' from your email address and try again.",
        );
      }

      const user = await User.findUserByEmail(userData.email, siteInfo.id);

      if (
        user &&
        user.status === 'inactive' &&
        user.email_verification_secret
      ) {
        return setVerifyUser(user);
      }

      if (user) {
        if (user.provider === 'default') {
          setError(
            'Customer with this email already exists. Choose a different email',
          );
        }
        if (user.provider === 'google') {
          setError(
            'You are already Signed up with google please use google to Sign up',
          );
        }
      } else {
        const newUser = await User.createNewUserWithCredentials({
          ...userData,
          provider: 'default',
        });
        sign_up(userData.email);
        await User.sendVerificationEmail(
          newUser.id,
          userData.email,
          siteInfo.site_name,
          siteInfo.site_url,
        );

        setVerifyUser({ ...newUser, emailSend: true });

        if (callback) callback();
        setError(null);
        e.target.reset();
      }
    } catch (e) {
      setError(e.message ?? e ?? 'Unable to Sign Up. Please Try Again');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Submits the login with Google request.
   *
   * @async
   * @function submitLoginWithGoogle
   * @returns {Promise<void>} A promise that resolves when the login request is completed.
   */
  const submitLoginWithGoogle = async () => {
    try {
      await signIn('google', { redirect: false });
      if (callback) callback();
    } catch (e) {
      toast.error(e.message ?? 'Unable to Sign In. Please Try Again.');
    }
  };

  const handleLoginClick = () => {
    openLoginModal();
  };

  const handleModalClose = () => {
    setError(null);
    closeModal();
  };

  useEffect(() => {
    setError(null);
  }, []);

  return (
    siteInfo && (
      <>
        <Modal
          centered
          show={show}
          onHide={handleModalClose}
          className="auth-popup"
        >
          <Modal.Body>
            <>
              <h4>Sign Up</h4>
              {siteInfo.login_method.includes('google') && (
                <LoginGoogle submitLoginWithGoogle={submitLoginWithGoogle} />
              )}
              {siteInfo.login_method.includes('credentials') && (
                <form onSubmit={(e) => submitRegister(e)}>
                  <div className="mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="d-grid mb-2">
                    <button
                      type="submit"
                      className="button-outlined"
                      disabled={loading}
                    >
                      <span>Sign Up</span>
                    </button>
                  </div>
                  <p className="forgot-password text-right">
                    By continuing, you agree to accept our Privacy Policy &
                    Terms of Service.
                  </p>
                  <p className="forgot-password text-right">
                    Already have an account? You can{' '}
                    <button
                      className="purple-button"
                      variant="primary"
                      onClick={handleLoginClick}
                    >
                      Login
                    </button>
                  </p>
                  {error && <p className="forgot-password error">{error}</p>}
                </form>
              )}

              {siteInfo.login_method.includes('shluchim') && <LoginShluchim />}
              <CheckEmailModal
                userData={verifyUser}
                closeModal={() => {
                  setVerifyUser(false);
                  closeModal();
                }}
              />
            </>
          </Modal.Body>
        </Modal>
      </>
    )
  );
};
export default RegisterModal;
