import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const FilterContext = createContext();

export const FilterProvider = ({ children, initialProps }) => {
  const [filters, setFilters] = useState({
    type: initialProps.productTypeQuery ? initialProps.productTypeQuery : [],
    language: initialProps.productLanguageQuery
      ? initialProps.productLanguageQuery
      : [],
    free: initialProps.productFreeQuery ? true : false,
  });

  const router = useRouter();

  // Function to add or update query parameters
  const addQueryParams = (filters) => {
    const params = { ...router.query };

    delete params.product_type;
    delete params.product_language;
    delete params.free;

    if (filters.type.length > 0)
      params.product_type = JSON.stringify(filters.type);
    if (filters.language.length > 0)
      params.product_language = JSON.stringify(filters.language);
    if (filters.free) params.free = 'true';

    router.push(
      {
        pathname: router.pathname,
        query: { ...params },
      },
      undefined,
      { shallow: true, scroll: false },
    );
  };

  // useEffect(() => {
  //   setFilters({
  //     type: initialProps.productTypeQuery ? initialProps.productTypeQuery : [],
  //     language: initialProps.productLanguageQuery
  //       ? initialProps.productLanguageQuery
  //       : [],
  //     free: initialProps.productFreeQuery ? true : false,
  //   });
  // }, [initialProps]);

  useEffect(() => {
    addQueryParams(filters);
  }, [filters]);

  return (
    <FilterContext.Provider value={{ filters, setFilters, addQueryParams }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilters must be used within an FilterProvider');
  }
  return context;
};
